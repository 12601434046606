export const clientPathBuilder = (clientId: number) => ({
  home: () => `/clients/${clientId}`,
  contact: () => `/clients/${clientId}/contact`,
  feedback: () => `/clients/${clientId}/feedback`,
  notice: () => `/clients/${clientId}/notice`,
  detail: () => ({
    index: () => `/clients/${clientId}/detail`,
    phoneNumber: (option?: { skipInputPhoneNumber?: boolean }) =>
      `/clients/${clientId}/detail/phone_number${
        option?.skipInputPhoneNumber ? '?skip_input_phone_number=1' : ''
      }`,
  }),
  offers: () => ({
    list: () => `/clients/${clientId}/offers`,
    detail: (offerId: number) => `/clients/${clientId}/offers/${offerId}`,
    create: () => `/clients/${clientId}/offers/new`,
    copy: (offerId: number) => `/clients/${clientId}/offers/new?offer_id=${offerId}`,
    edit: (offerId: number) => `/clients/${clientId}/offers/${offerId}/edit`,
  }),
  graduates: () => ({
    top: () => `/clients/${clientId}/graduates`,
    offers: () => ({
      detail: (offerId: number) => `/clients/${clientId}/graduates/offers/${offerId}`,
    }),
    offerings: () => ({
      new: (offerId: number) => `/clients/${clientId}/graduates/offers/${offerId}/offerings/new`,
      confirm: (offerId: number) =>
        `/clients/${clientId}/graduates/offers/${offerId}/offerings/confirm`,
    }),
  }),
  offerings: () => ({
    list: () => `/clients/${clientId}/offerings`,
    bulkCreate: (baseOfferingId: number) =>
      `/clients/${clientId}/offerings/bulk_create?offeringId=${baseOfferingId}`,
    detail: (offeringId: number) => `/clients/${clientId}/offerings/${offeringId}`,
    userReject: (offeringId: number, userId: number) =>
      `/clients/${clientId}/offerings/${offeringId}/reject/${userId}`,
    new: (offerId: number) => `/clients/${clientId}/offers/${offerId}/offerings/new`,
    edit: (offerId: number, offeringId: number) =>
      `/clients/${clientId}/offers/${offerId}/offerings/${offeringId}/edit`,
    copy: (offerId: number, offeringId: number) =>
      `/clients/${clientId}/offers/${offerId}/offerings/new?offering_id=${offeringId}`,
    confirm: (offerId: number) => `/clients/${clientId}/offers/${offerId}/offerings/confirm`,
  }),
  reviews: () => ({
    client: () => `/clients/${clientId}/reviews/client`,
    users: () => `/clients/${clientId}/reviews/users`,
    offerings: (offeringId: number) => ({
      users: () => `/clients/${clientId}/offerings/${offeringId}/reviews/users`,
    }),
  }),
  fixPaymentRequests: () => `/clients/${clientId}/fix_payment_requests`,
  attendances: () => `/clients/${clientId}/attendances`,
  users: () => ({
    invitation: () => `/clients/${clientId}/users/invitation`,
    groups: () => `/clients/${clientId}/users/groups`,
    list: () => `/clients/${clientId}/users`,
    listWithType: (opt: { listType: number }) =>
      `/clients/${clientId}/users?list_type=${opt.listType}`,
    detail: (userId: number) => `/clients/${clientId}/users/${userId}`,
  }),
  statements: () => `/clients/${clientId}/statements`,
  chat: () => ({
    list: (keyword?: string) =>
      `/clients/${clientId}/chat_rooms${keyword ? `?keyword=${keyword}` : ''}`,
    chatRoom: (chatRoomId: string) => `/clients/${clientId}/chat_rooms/${chatRoomId}`,
  }),
  requestChangeUsageLimit: () => `/clients/${clientId}/request_change_usage_limit`,
  requestLiftSuspension: (option?: { refOfferId?: number }) =>
    `/clients/${clientId}/request_lift_suspension` +
    (option?.refOfferId ? `?ref_offer_id=${option?.refOfferId}` : ''),
});

export type IClientPathBuilder = typeof clientPathBuilder;
